global.addressAutocompleteInit = (locationiqPublicMapApiKey, markerImgUrl, defaultLatitude, defaultLongitude) => import('./components/address_autocomplete').then(module => module.addressAutocompleteInit(locationiqPublicMapApiKey, markerImgUrl, defaultLatitude, defaultLongitude));
global.addressFormInit = (locationiqPublicMapApiKey, markerImgUrl, defaultLatitude, defaultLongitude, searchableCountryCodes) =>
  import('./components/address_form').then(module => module.addressFormInit(locationiqPublicMapApiKey, markerImgUrl, defaultLatitude, defaultLongitude, searchableCountryCodes));
global.addressFormChangeSearchMode = () => import('./components/address_form').then(module => module.addressFormChangeSearchMode());

global.initSlots = (id) => import('./inputs/slots').then(module => module.initSlots(id));

global.initRating = (node) => import('./inputs/rating').then(module => module.initRating(node));

global.clearInput = () => import('./inputs/clear_input').then(module => module.clearInput());

document.arrive('select.rating', { existing: true }, function() {
  global.initRating($(this));
});

global.submitFormWithTurbo = (selector) => {
  const form = $(selector);
  const fakeButton = $('<button/>', { type: 'submit', style: 'display: none' });
  form.append(fakeButton);
  fakeButton.click();
  fakeButton.remove();
};
